var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Identification" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Name",
                                        name: "name",
                                      },
                                      model: {
                                        value: _vm.formDataF["name"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formDataF, "name", $$v)
                                        },
                                        expression: "formDataF['name']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "IMO Number",
                                        name: "imoNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["imoNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "imoNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['imoNumber']",
                                      },
                                    }),
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.countryReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "ctyDsc",
                                        "item-value": "ctyCod",
                                        label: "Flag State",
                                        mandatory: "",
                                        name: "flagState",
                                        "show-item-avatar": "",
                                        sortable: "",
                                      },
                                      model: {
                                        value: _vm.formData["flagState"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "flagState",
                                            $$v
                                          )
                                        },
                                        expression: "formData['flagState']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Call Sign",
                                        name: "callSign",
                                      },
                                      model: {
                                        value: _vm.formDataF["callSign"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "callSign",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['callSign']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "MMSI Number",
                                        name: "mmsiNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["mmsiNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "mmsiNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['mmsiNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Details" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.typeReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "name",
                                        "item-value": "code",
                                        label: "Vessel Type",
                                        mandatory: "",
                                        name: "type",
                                        sortable: "",
                                      },
                                      model: {
                                        value: _vm.formDataF["type"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formDataF, "type", $$v)
                                        },
                                        expression: "formDataF['type']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Overall Length",
                                        name: "overallLength",
                                      },
                                      model: {
                                        value: _vm.formDataF["overallLength"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "overallLength",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['overallLength']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Height",
                                        name: "height",
                                      },
                                      model: {
                                        value: _vm.formDataF["height"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formDataF, "height", $$v)
                                        },
                                        expression: "formDataF['height']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Gross Tonnage",
                                        name: "grossTonnage",
                                      },
                                      model: {
                                        value: _vm.formDataF["grossTonnage"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "grossTonnage",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['grossTonnage']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Draught",
                                        name: "draught",
                                      },
                                      model: {
                                        value: _vm.formDataF["draught"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "draught",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['draught']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Year Of Build",
                                        name: "builtYear",
                                      },
                                      model: {
                                        value: _vm.formDataF["builtYear"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "builtYear",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['builtYear']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Beam",
                                        name: "beam",
                                      },
                                      model: {
                                        value: _vm.formDataF["beam"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formDataF, "beam", $$v)
                                        },
                                        expression: "formDataF['beam']",
                                      },
                                    }),
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.hullTypeReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "name",
                                        "item-value": "code",
                                        label: "Hull Type",
                                        mandatory: "",
                                        name: "hullType",
                                        sortable: "",
                                      },
                                      model: {
                                        value: _vm.formDataF["hullType"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "hullType",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['hullType']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Net Tonnage",
                                        name: "netTonnage",
                                      },
                                      model: {
                                        value: _vm.formDataF["netTonnage"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "netTonnage",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['netTonnage']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Deadweight",
                                        name: "deadWeight",
                                      },
                                      model: {
                                        value: _vm.formDataF["deadWeight"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "deadWeight",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['deadWeight']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("asyent-form-textarea", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        flat: "",
                                        label: "Additional Info / Remarks",
                                        name: "additionalInfo",
                                        "no-resize": "",
                                      },
                                      model: {
                                        value: _vm.formDataF["additionalInfo"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "additionalInfo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['additionalInfo']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Contact Information" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("VesselsContacts", {
                                      attrs: {
                                        "is-disabled-func": _vm.isDisabled,
                                        "item-headings": [
                                          "Contact Medium",
                                          "Value",
                                        ],
                                        "item-values": [
                                          "contactMedium",
                                          "contactValue",
                                        ],
                                        readonly: _vm.isReadonly,
                                        name: "contacts",
                                        title: "Contacts",
                                      },
                                      model: {
                                        value: _vm.formDataF["contacts"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "contacts",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['contacts']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Owner" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Code",
                                        name: "owner.code",
                                      },
                                      model: {
                                        value: _vm.formDataF["owner"]["code"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["owner"],
                                            "code",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['owner']['code']",
                                      },
                                    }),
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "IMO Number",
                                        name: "owner.imoNumber",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["owner"]["imoNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["owner"],
                                            "imoNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['owner']['imoNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Name",
                                        name: "owner.name",
                                      },
                                      model: {
                                        value: _vm.formDataF["owner"]["name"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["owner"],
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['owner']['name']",
                                      },
                                    }),
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload":
                                          _vm.imoOrganisationReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "name",
                                        "item-value": "code",
                                        label: "Type",
                                        mandatory: "",
                                        name: "owner.type",
                                        sortable: "",
                                      },
                                      model: {
                                        value: _vm.formDataF["owner"]["type"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF["owner"],
                                            "type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['owner']['type']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Certificate of Registry" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.locodeReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "locDsc",
                                        "item-value": "locCod",
                                        label: "Registry Port",
                                        name: "registryCertificate.registryPort",
                                        sortable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formData["registryCertificate"][
                                            "registryPort"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData["registryCertificate"],
                                            "registryPort",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['registryCertificate']['registryPort']",
                                      },
                                    }),
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Issue Date",
                                        name: "registryCertificate.issueDate",
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["registryCertificate"][
                                            "issueDate"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF[
                                              "registryCertificate"
                                            ],
                                            "issueDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['registryCertificate']['issueDate']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Number",
                                        name: "registryCertificate.number",
                                      },
                                      model: {
                                        value:
                                          _vm.formData["registryCertificate"][
                                            "number"
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData["registryCertificate"],
                                            "number",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['registryCertificate']['number']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }